import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateAppLayout from '../layouts/PrivateAppLayout';
import { NotFound } from '../modules/NotFound';
import AddBuHead from '../modules/Bu/Component/AddBuHead';
import BudgetReport from '../modules/BudgerReport/BudgetReport';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const LoginPage = lazy(() => import('../modules/Login/Login'));
const DashbaordPage = lazy(() => import('../modules/Dashboard/Dashboard'));
const CategoriesPage = lazy(() => import('../modules/Categories/Categories'));
const SubCategoriesPage = lazy(() => import('../modules/SubCategories/SubCategories'));
const BadgesPage = lazy(() => import('../modules/Badges/Badges'));
const DesignationsPage = lazy(() => import('../modules/Designations/Designations'));
const KRAPage = lazy(() => import('../modules/KRA/KRA'));
const UsersPage = lazy(() => import('../modules/Users/Users'));
const BuPage = lazy(() => import('../modules/Bu/Bu'));
const EligibilityPage = lazy(() => import('../modules/Eligibility/Eligibility'));
const AppraisalPage = lazy(() => import('../modules/AppraisalAllocation/AppraisalAllocation'));
const SlabMapping = lazy(() => import('../modules/SlabMapping/SlabMapping'));
const ScoreMappingPage = lazy(() => import('../modules/ScoreMapping/ScoreMapping'));
const AddCategoryPage = lazy(() => import('../modules/Categories/Component/AddCategoriesForm'));
const AddSubCategoryPage = lazy(() => import('../modules/SubCategories/Component/AddSubCategroyForm'));
const AddBadgeForm = lazy(() => import('../modules/Badges/Component/AddBadgeForm'));
const AddKRAForm = lazy(() => import('../modules/KRA/Component/AddKRAForm'));
const ChangePassword = lazy(() => import('../modules/Login/Component/ChangePassword'));

const AppRouter = () => {
  const isLoading = useSelector((state: RootState) => state.roles.isLoading);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path='/dashboard'
          element={
            <PrivateAppLayout>
              <DashbaordPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/categories'
          element={
            <PrivateAppLayout>
                <ProtectedRoute code='ADCAT' />
                <CategoriesPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/sub-categories'
          element={
            <PrivateAppLayout>
                <ProtectedRoute code='ADSUBCAT' />
              <SubCategoriesPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/badges'
          element={
            <PrivateAppLayout>
                <ProtectedRoute code='ADBADGE' />
              <BadgesPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/score-mapping'
          element={
            <PrivateAppLayout>
                <ProtectedRoute code='ADSM' />
              <ScoreMappingPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/slab-mapping'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADSLM' />
              <SlabMapping />
            </PrivateAppLayout>
          }
        />
        <Route
          path="/designations"
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADDSGN' />
              <DesignationsPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/kra'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADKRA' />
              <KRAPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/users'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADUSR' />
              <UsersPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/bu'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADBU' />
              <BuPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/eligibility'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADELG' />
              <EligibilityPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/appraisal'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADAPPRAISAL' />
              <AppraisalPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/budget-report'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code='ADBUDGETREP' />
                <BudgetReport />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/categories/create-category'
          element={
            <PrivateAppLayout>
                {' '}
                <ProtectedRoute code="ADCAT:SUBCATDET" actionTypes={['Write', 'Edit']} />
                  <AddCategoryPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/sub-categories/create-sub-category'
          element={
            <PrivateAppLayout>
              {' '}
              <ProtectedRoute code="ADSUBCAT:SUBSUBCATDET" actionTypes={['Write', 'Edit']} />
              <AddSubCategoryPage />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/badges/create-badge'
          element={
            <PrivateAppLayout>
              {' '}
              <ProtectedRoute code="ADBADGE:SUBBADGEDET" actionTypes={['Write']} />
              <AddBadgeForm />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/kra/create-kra'
          element={
            <PrivateAppLayout>
              {' '}
              <ProtectedRoute code="ADKRA:SUBKRADET" actionTypes={['Write']} />
              <AddKRAForm />
            </PrivateAppLayout>
          }
        />
        <Route
          path='/bu/Add-businessUnit'
          element={
            <PrivateAppLayout>
              <ProtectedRoute code="ADBU:SUBBUDET" actionTypes={['Write']} />
              <AddBuHead />
            </PrivateAppLayout>
          }
        />
        {/* Public Route */}
        <Route path='/login' element={<LoginPage />} />
        <Route
          path='/chnage-password'
          element={
            <PrivateAppLayout>
              {' '}
              <ChangePassword />
            </PrivateAppLayout>
          }
        />
        {/* {/* Public Route */}
        <Route path='/' element={<LoginPage />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
