export const token = localStorage.getItem('token');

export const userId = localStorage.getItem('user_id');

export const email = localStorage.getItem("user");

export const department = localStorage.getItem('department');

export const getAuthHeader = {
  'x-idToken': token || '',
  'x-user_id': userId || '',
  'user': email || '',
  'x-department': department || ''
};
