export default {
  apiBaseUrl: process.env.REACT_APP_API_URL || 'https://uat-pms-backend.successive.work/api/admin',
  debug: process.env.REACT_APP_API_DEBUG,
  pmsApiBaseUrl: process.env.REACT_APP_PMS_API_URL || 'https://uat-pms-backend.successive.work/api/admin',
  rbacApiBaseUrl: process.env.REACT_APP_RBAC_API_URL || 'https://uat-rbac-backend.successive.work/api',
  awsAsccessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  awsSecretKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },
};
