import React, { useEffect, useState } from 'react';
import { ListTable } from '../../components';
import { TableColumn, TableData } from '../../components/ListTable';
import { convertArrayOfObjectsToCsv } from '../../components/CsvHelper/CsvHelper';
import { TableSortLabel } from '@mui/material';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getAuthHeader } from '../../utils/utils';

const BudgetReport: React.FC = () => {
  const [data, setData] = useState<TableData[]>([]);
  const [buOptions, setBuOptions] = useState<string[]>([]);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  useEffect(()=>{
    const title = document.querySelector('title');
    if (title) {
      title.innerText = 'Budget Report';
    }
    const fetchData = async () =>{
try {
  const response = await CommHandler.request({
    method: 'GET',
    url: `${config.pmsApiBaseUrl}/budget-report`,
    headers: getAuthHeader,

  })

  const transformedData = response?.data?.map((item: any) => {
    return {
      name: item?.fullName,
      buHead: item?.department,
      fy: item?.financial_year,
      assignedAppraisal: item?.achieved_appraisal_percentage,
      eligibleAppraisal: item?.total_appraisal_percentage,
    };
  });
  setData(transformedData);

const BuOptions = response.data
.map((item: any) => item?.department)
.filter((value:any, index:any , self:any) => self.indexOf(value) === index);

  setBuOptions(BuOptions);
} catch (error) {
  console.error(" fetching data in budget Report", error)
}
    }
    fetchData()
  },[])

  const handleSortRequest = (column: string) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy === 'assignedAppraisal' || orderBy === 'eligibleAppraisal') {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (aValue < bValue) return orderDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return orderDirection === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const columns: TableColumn[] = [
    { header: 'name', accessor: 'name' },
    { header: 'BU', accessor: 'buHead' },
    { header: 'FY', accessor: 'fy' },
    {
      header: (
        <TableSortLabel
          active={orderBy === 'assignedAppraisal'}
          direction={orderDirection}
          onClick={() => handleSortRequest('assignedAppraisal')}
        >
          Achieved Appraisal Percentage
        </TableSortLabel>
      ),
      accessor: 'assignedAppraisal',
    },
    {
      header: (
        <TableSortLabel
          active={orderBy === 'eligibleAppraisal'}
          direction={orderDirection}
          onClick={() => handleSortRequest('eligibleAppraisal')}
        >
          Total Appraisal Percentage       
        </TableSortLabel>
      ),
      accessor: 'eligibleAppraisal',
    },
  ];

  const downloadCsvReport = () => {
    const result = data.map((row) => ({
      Name: row.name,
      BU: row.buHead,
      FY: row.fy,
      'Assigned Appraisal': row.assignedAppraisal,
      'Eligible Appraisal': row.eligibleAppraisal,
    }));

    const csvData = convertArrayOfObjectsToCsv(result, [
      { label: 'Name', key: 'Name' },
      { label: 'BU', key: 'BU' },
      { label: 'FY', key: 'FY' },
      { label: 'Achieved Appraisal', key: 'Assigned Appraisal' },
      { label: 'Eligible Appraisal', key: 'Eligible Appraisal' },
    ]);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'budget_report.csv';
    a.click();
  };

  return (
    <div className='bu-comp-wrap'>
      <ListTable
        columns={columns}
        timeout={5000}
        tabValue={''}
        data={sortedData}
        isStatus={false}
        heading={'Budget Report'}
        buOptions={buOptions}
        downloadCsvReport={downloadCsvReport}
      />
    </div>
  );
};

export default BudgetReport;
