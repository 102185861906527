import * as React from 'react';
import { useDropzone } from 'react-dropzone';
// import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { config } from '../../config';
import { CommHandler } from '../../utils';
import { getAuthHeader } from '../../utils/utils';


export default function UploadFile(props: any) {
  const { setValidationMessage, data, multiple, onFileUpload } = props;
  const [files, setFiles] = React.useState<File[]>([]);
  const [uploading, setUploading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const formData = new FormData();
    if (files.length > 0 && !uploading && !multiple) {
      // Files were selected and no upload is in progress, initiate upload
      uploadFiles();
    }
    files.forEach((file) => formData.append("files", file));
    const fileNames = files.map((file) => file.name);

    // onChange({ ...data, file_path: files });
  }, [files, data]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: any[]) => {
      if (!multiple && files.length > 0) {
        setFiles(acceptedFiles);
        setUploading(false);
      }
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        fileError: '',
      }));
      const invalid = acceptedFiles.filter((file: { size: number; }) => file.size > 5242880);
      if (invalid.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File Size cannot be more than 5MB',
        }));
      } else if (!multiple && files.length > 0) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '',// You can only upload a single file
        }));
      } else if ((files.length + acceptedFiles.length) > 5) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'You cannot upload more than 5 Files',
        }));
      } else {
        setFiles((existing) => [...existing, ...acceptedFiles]);
      }
    },
  });

  const fileInputReference = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {

    if (fileInputReference.current) {
      fileInputReference.current.click();
    }
  };

  const removeElement = (index: any) => {
    const filteredFiles = files.filter((file: any) => file.name !== index);
    setValidationMessage((prevMessages: any) => ({
      ...prevMessages,
      fileError: '',
    }));
    setFiles(filteredFiles);
  };
  const uploadFiles = async () => {
    if (uploading) {
      // Don't initiate another upload if one is already in progress
      return;
    }
    setLoading(true); // Set loading to true to show the loader

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    try {
      setUploading(true); // Set uploading to true to prevent multiple uploads
      const response: any = await CommHandler.request({
        url: `${config.apiBaseUrl}/badges/upload`,
        method: 'POST',
        data: formData,
        headers: {
          ...getAuthHeader,
          'Content-Type': 'multipart/form-data',
        },
      });
      onFileUpload(response.fileDataArray);
    } catch (error) {
      console.error("Network error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {files.map((file) => (
        <div key={file.name}>
          <Box
            sx={{
              width: '100%',
              height: 48,
              padding: '8px 16px',
              backgroundColor: 'rgba(200, 200, 200, 0.05)',
              border: '2px solid rgba(166, 167, 168, 0.30)',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
              alignSelf: 'stretch',
              marginBottom: '5px',
            }}
          >

              <TextSnippetOutlinedIcon sx={{ color: 'rgba(156, 163, 175, 1)' }} />

      {loading ?  <CircularProgress size={20} color="inherit" /> : file.name}
            {/* <Button variant="outlined">
              <AutorenewOutlinedIcon sx={{ color: 'rgba(37, 89, 195, 1)' }} />
            </Button> */}

            {/* <Button variant="outlined" onClick={() => removeElement(file.name)}>
              <DeleteForeverOutlinedIcon sx={{ color: 'rgba(222, 78, 53, 1)' }} />
            </Button> */}
          </Box>
        </div>
      ))}
      <div>
        <Box
          {...getRootProps()}
          sx={{
            width: '100%',
            height: 156,
            padding: '24px',
            backgroundColor: 'rgba(37, 89, 195, 0.05)',
            border: '2px dashed rgba(37, 89, 195, 0.30)',
            borderRadius: '6px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            alignSelf: 'stretch',
          }}
        >
          <CloudUploadOutlinedIcon fontSize="large" />
          Drag and Drop Certificate files or CHOOSE FILES
          <input {...getInputProps()} accept="image/jpeg, image/png, application/pdf" />
          <Button variant="outlined" onClick={handleClick}>Choose Files</Button>
        </Box>
      </div>
    </>
  );
}
