import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse,
} from 'axios';
import { v4 as uuidv4 } from 'uuid';
import config from '../config/config';
import Snackbar, { showSuccessSnackbar, showErrorSnackbar } from './Snackbar';

const headers: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'x-trace-id': uuidv4(),
};
export const currentFinancialYear = "2024-2025";

const CommHandler: AxiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    ...headers,
  },
});

const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers = request.headers || {};
    request.headers['x-idToken'] = localStorage.getItem('token') || '';
    request.headers['x-user_id'] = localStorage.getItem('user_id') || '';
    request.headers['x-department'] = localStorage.getItem('department') || '';
    request.headers['user'] = localStorage.getItem('user') || '';
    return request;
}
const responseSuccessInterceptor = (response: AxiosResponse) => {
  // Show a success Snackbar notification
  // showSuccessSnackbar('Request successful');
  return Promise.resolve(response.data);
};

const responseErrorInterceptor = (err: AxiosError) => {
  if (err.response) {
    console.log(err);
    let errorMessage: string = 'Unknown Error'; // Initialize with a default value

    const response = err.response as { data?: { message?: string; status?: number }; statusText?: string };

    if (response.data?.message === "Data not found" && response.data.status === 400) {
      errorMessage = response.data.message;
    } 
    else if(err.response?.status === 401){
      window.location.href = '/'
    }
    else {
       errorMessage =
        (err.response.data as { error: [{ msg?: string }] })?.error?.[0]?.msg ||
        err.response.statusText ||
        'Unknown Error';
    }
    const errorMessageCapitalized = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

    // Show an error Snackbar notification
    showErrorSnackbar(errorMessageCapitalized);

    return Promise.reject(new Error(errorMessageCapitalized));
  }
  // Handle other types of errors
  const genericErrorMessage = 'Something went wrong';
  showErrorSnackbar(genericErrorMessage);

  return Promise.reject(new Error(genericErrorMessage));
};

CommHandler.interceptors.request.use(requestInterceptor);
CommHandler.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export default CommHandler;
